import React, { useState } from 'react';
import { Link } from 'gatsby';

import profilePhoto from '../assets/profile-photo.jpeg';

const Header = () => {
  const [open, setOpen] = useState(false);

  return (
    <header className="sticky z-40 top-0 py-2 flex justify-center bg-gray-900 text-gray-400 shadow">
      <div className="max-w-6xl w-full h-full mx-4">
        <div className="py-3 h-full flex items-center justify-between">
          <div className="flex">
            <img className="w-12 h-12 rounded-full mr-4 sm:w-16 sm:h-16 xl:w-20 xl:h-20" src={profilePhoto} alt="Profile of Jared Kanesiro" />
            <div className="flex flex-col md:flex-row md:items-center justify-between text-base sm:text-lg md:text-xl xl:text-2xl">
              <h1 className="text-gray-300 font-bold">Jared Kaneshiro</h1>
              <span className="hidden md:block mx-4"> | </span>
              <h2 className="text-gray-500 font-semibold">Software Engineer</h2>
            </div> 
          </div>
          <nav className="hidden lg:flex justify-between text-base xl:text-lg">
            <Link to="/" className="px-2 xl:px-2 2xl:px-4 py-1 rounded hover:bg-gray-800" activeClassName="text-teal-500">Home</Link>
            <Link to="/about" className="px-2 xl:px-2 2xl:px-4 py-1 rounded hover:bg-gray-800" activeClassName="text-teal-500">About</Link>
            <Link to="/portfolio" className="px-2 xl:px-2 2xl:px-4 py-1 rounded hover:bg-gray-800" activeClassName="text-teal-500">Portfolio</Link>
            <Link to="/blog" className="px-2 xl:px-2 2xl:px-4 py-1 rounded hover:bg-gray-800" activeClassName="text-teal-500">Blog</Link>
            <Link to="/contact" className="px-2 xl:px-2 2xl:px-4 py-1 rounded hover:bg-gray-800" activeClassName="text-teal-500">Contact</Link>
          </nav>
          <button
            aria-label="Main menu"
            aria-expanded={open ? "true" : "false"}
            onClick={() => setOpen(!open)}
            className="block text-gray-500 hover:text-white focus:text-white focus:outline-none lg:hidden">
            <svg className="h-8 w-8 md:h-10 md:w-10 fill-current" viewBox="0 0 24 24">
              {open ? <path fillRule="evenodd" d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z" /> :
                <path fillRule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z" />
              }
            </svg>
          </button>
        </div>
        <nav className={open ? "py-2 flex flex-col lg:hidden text-sm sm:text-base md:text-lg" : "hidden"}>
          <Link to="/" className="mr-12 px-2 py-1 rounded hover:bg-gray-800" activeClassName="text-teal-500">Home</Link>
          <Link to="/about" className="mr-12 px-2 py-1 rounded hover:bg-gray-800" activeClassName="text-teal-500">About</Link>
          <Link to="/portfolio" className="px-2 xl:px-4 2xl:px-6 py-1 rounded hover:bg-gray-800" activeClassName="text-teal-500">Portfolio</Link>
          <Link to="/blog" className="mr-12 px-2 py-1 rounded hover:bg-gray-800" activeClassName="text-teal-500">Blog</Link>
          <Link to="/contact" className="mr-12 px-2 py-1 rounded hover:bg-gray-800" activeClassName="text-teal-500">Contact</Link>
        </nav>
      </div>
    </header>
  )
};

export default Header;