import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

/**
 * Function to create HTML head content for each page
 * @function head
 * @param {object} props React props
 * @param {string} props.title title of page
 * @returns {JSX.Element} Rendered component
 */

const Head = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);
  return (
    <Helmet title={`${data.site.siteMetadata.title} | ${title}`}>
      <html lang="en" />
    </Helmet>
  );
};

export default Head;