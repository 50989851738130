import React from 'react';
import Header from './header';
import Footer from './footer';

const Layout = (props) => {
  return (
    <div className="min-h-screen flex flex-col text-gray-900 font-mono">
      <Header />
      <div className="flex-1 flex justify-center">
        <main className="max-w-6xl w-full mx-5 pt-4 lg:pt-12">
          {props.children}
        </main>
      </div>
      <Footer/>
    </div>
  )
};

export default Layout;