import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { faLinkedin, faGithub, faAngellist } from '@fortawesome/free-brands-svg-icons';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import resume from '../assets/jared-kaneshiro_resume.pdf';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `);
  return (
    <footer className="shadow bg-gray-900 text-gray-400 flex flex-col items-center">
      <ul className="flex items-center justify-center mt-6 mb-4">
        <li><a
          href="https://www.linkedin.com/in/jared-kaneshiro-9b83322b/"
          target="_blank"
          rel="noreferrer"
          className="mx-2 md:mx-4 text-lg md:text-2xl hover:text-teal-500 hover:font-bold"
        ><FontAwesomeIcon icon={faLinkedin} alt="Redirect to my LinkedIn profile page" /></a>
        </li>
        <li><a
          href="https://github.com/jmkaneshiro"
          target="_blank"
          rel="noreferrer"
          className="mx-2 md:mx-4 text-lg md:text-2xl hover:text-teal-500 hover:font-bold"
        ><FontAwesomeIcon icon={faGithub} alt="Redirect to my Github profile page" /></a></li>
        <li><a
          href="https://angel.co/u/jared-kaneshiro-1"
          target="_blank"
          rel="noreferrer"
          className="mx-2 md:mx-4 text-lg md:text-2xl hover:text-teal-500 hover:font-bold"
        ><FontAwesomeIcon icon={faAngellist} alt="Redirect to my Angel List profile page" /></a></li>
        <li><a
          href="mailto:jmkaneshiro@gmail.com"
          target="_blank"
          rel="noreferrer"
          className="mx-2 md:mx-4 text-lg md:text-2xl hover:text-teal-500 hover:font-bold"
        ><FontAwesomeIcon icon={faEnvelope} alt="Send me an email" /></a></li>
        <li><a
          href={resume}
          target="_blank"
          rel="noreferrer"
          className="mx-2 md:mx-4 text-sm md:text-lg hover:text-teal-500 hover:font-bold"
        >Resume <FontAwesomeIcon icon={faExternalLinkAlt} alt="View my resume" /></a></li>
      </ul>
      <p className="pb-6 text-xs sm:text-sm md:text-base">Created by {data.site.siteMetadata.author} © 2020</p>
    </footer>
  )
}

export default Footer;